window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

document.addEventListener("turbo:load", function (event) {
  gtag("config", "AW-998965315", {
    page_location: event.detail.url,
    page_path: event.currentTarget.location.pathname,
    page_title: event.currentTarget.title,
  });
});
